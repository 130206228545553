import React, { useEffect, Fragment, useMemo, useState,useRef } from 'react';



import MaterialTable from 'material-table';
import 'whatwg-fetch';
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import ReactLoading from 'react-loading';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputLabel from "@material-ui/core/InputLabel";
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import Button from '@material-ui/core/Button';
import novacancies from "../../images/novacancies.png"
import thanks from "../../images/thanks.png"

// export default class Lab_Tests extends React.Component {
//     constructor(props){
//         super(props);
//         this.state = {
//             columns: [
//               { title: 'Date', field: 'job_date', editable: 'never' }, // 'never' means it's not editable
//               { title: 'Job_Title', field: 'job_title' },
//               { title: 'Job Details', field: 'job_details' },
//               { title: 'Degree', field: 'degree_required' },
//               { title: 'Experience', field: 'experience_required' },
//               { title: 'Degree', field: 'degree_required' },
//               ],
//               data: [
                
//               ],
//               loading:true
                
//         }
//         this.getData=this.getData.bind(this)
//     }
    
//     getData() {
//         var columns = this.state.columns;
//         var rows = this.state.data;
//         return { columns, rows};
//       }
//       componentWillMount(){
//         //  fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestsPriceList?vQuery={%22PIN%22:%22%22,%22PhoneNo%22:%22%22,%22PatientName%22:%22%22,%22RegDateFrom%22:%222000-02-01T04:08:27.995Z%22,%22RegDateTo%22:%222020-08-12T04:08:27.995Z%22}&vUID=120720001&vRole=Patient"
        
//         // )
//         fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebGetPriceListsBranchPanelS", {
//           method: "POST",
//           'Content-Type': "application/json",
//           body: JSON.stringify({
    
//             "branch_id": 0,
//             "panel_code": ""
    
//           })
//         })
//         .then((res)=>res.json())
//          .then((result)=>{
//           console.log(result)
//            this.setState({data:result})
//            this.setState({loading:false})
//          })
       
//       }

//     render(){
//         return (
//           <>
// {this.state.loading ?
// <center>
//   <ReactLoading type={'spinningBubbles'} color={'#196c90'}  height={'10em'} width={'10em'}/>
//   </center>
//   :
//   <MaterialTable
//   options={{
//     // selection: true,
//     // paging:true,
//     // minBodyHeight:40,
//     headerStyle: {
//       backgroundColor: '#196c91',
//       color: '#FFF'
//     }
   
//     // showSelectAllCheckbox:SelectionControl
//   }}
//       title="Find a Test / Service"
//       columns={this.state.columns}
//       data={this.state.data}
    
  
//           />
// }
// </>
            
//         )
//     }
// }
export default function Lab_Tests() {

  const [allJobs,setallJobs]=useState([])
  const [selectJob,setselectJob]=useState()
  const [name ,setname]=useState("")
  const [phoneNo ,setphoneNo]=useState("")
  const [ScanPDF, setScanPDF] = React.useState('')
  const [PDFDetails, setPDFDetails] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [submissionSuccess,setsubmissionSuccess]=useState(false)

  // for pdf 
const fileInputRefpdf = useRef(null);


const handleButtonClickpdf = () => {
 fileInputRefpdf.current.click();
};
  const jobColumns = [
    { title: 'Date', field: 'job_date', editable: 'never' }, // 'never' means it's not editable
    { title: 'Job_Title', field: 'job_title' },
    { title: 'Job Details', field: 'job_details' },
    { title: 'Degree', field: 'degree_required' },
    { title: 'Experience', field: 'experience_required' },
    { title: 'Degree', field: 'degree_required' },
  ];
  useEffect(() => {
    var obj = {
      // branch_id:parseInt(branchID),
      branch_id:1,
      job_title:""
    
    }
    console.log(obj)
     fetch("https://reports.mpl-labs.pk:8443/4DACTION/JobsOpeningCallingUpWS_api", {
        method: "POST",
        body: JSON.stringify(obj)
    }).then((res) => res.json()).then((response) => {
      console.log(response)
      setallJobs(response)

    })
  },[])
  const handleFileChangepdf = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        alert('Invalid file type. Please select a PDF file.');
       
        return;
      }
   
      // setFileName(file.name); // Display file name
      console.log("pdf")
      setPDFDetails(file)
      convertToBase64pdf(file);  // Convert file to Base64
      // console.log()
    }
   };
   const convertToBase64pdf = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      // setBase64(reader.result); // Set Base64 string
           setScanPDF(reader.result)
      // console.log(reader.result)
      // setScanImage(reader.result)
    };
    reader.readAsDataURL(file);
   };
  return (

    <GridContainer  style={{ marginTop:"" ,}}>
{allJobs.length>0 && !submissionSuccess?<>
                <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom:"1%",marginTop:"0.5em",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}  >
   
    <Autocomplete
   
      id="country-select"
      options={allJobs}
      classes={{
        // option: classes.option
      }}
      autoHighlight
       onChange={ async(event, newValue) => {
        
        if(newValue!=null){
          console.log(newValue)
    setselectJob(newValue.job_id)
          // handleChange({
          //   target:{
          //     value:newValue.Panel_Code
          //   }
          // })
          // setselectedPanel(newValue.Panel_Code)
      //     var lab_tests = []
      //     var text = newValue.Panel_Code
          
         
      //     var data = await org_rows.map((item) => {
        
      //       if (item.Panel_Code.includes(text)) {
      //         lab_tests.push(item)
      //         return
      
      //       }
      
            
      
      //       return item
      
      //     })
      //     Promise.all(data).then((item) => {
      // console.log(lab_tests)
      //       setRows(lab_tests)
      //               // 20-july-2024 for search issue of panel when we select the panel its show all test in search bar 
    
      //       setPanel_Rows(lab_tests)
      
      //     })
      // // console.log(selectedPanel)
      // setselectedPanel(newValue.Panel_Name  )
          
        }else{
          // handleChange({
          //   target:{
          //     value:"Normal"
          //   }
          // })
          // setRows(org_rows)
          // setPanel_Rows([])
          // setselectedPanel("")
          setselectJob("")
        }
      }}
      getOptionLabel={(option) => option.job_title}
      style={{
        width:window.innerWidth <= 768?"15em":"20em"
      }}
      renderOption={(option) => <React.Fragment>{option.job_title}</React.Fragment>}
      
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a Job"
          variant="outlined"
          style={{
            // width:"10em"
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password" // disable autocomplete and autofill
          }}
        />
      )}
    />
    </GridItem>

     <GridItem xs={12} sm={12} md={12} lg={12} 
     style={{marginBottom:"1%",marginTop:"0.5em",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}
     >
                {/* <InputLabel 
                // className={classes.label}
                style={{marginLeft:"37.5%"}}
                >
                  <b>Enter Full Name</b>
                </InputLabel>
                <TextField
                style={{
                  width:"20em",
                  marginLeft:"37.5%"
                }}
                value={name}
                onChange={(e)=>{
                  setname(e.target.value)
                }}
                /> */}
                <TextField
                
      id="outlined-required"
      label="Enter Full Name"
      value={name}
      onChange={(e) => {
        setname(e.target.value);
      }}
      style={{
        // width:"20em",
        width:window.innerWidth <= 768?"15em":"20em"

                  // marginLeft:"37.5%"
      }}
      variant="outlined"
    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12} 
                style={{marginBottom:"1%",marginTop:"0.5em",display:"flex",flexDirection:"column",alignItems:"center"}}
                >
                {/* <InputLabel 
                // className={classes.label}
                style={{textAlign:"left"}}
                >
                  <b>Enter WhatsApp No</b>
                </InputLabel>
                <TextField
                value={phoneNo}
                onChange={(e)=>{
                  setphoneNo(e.target.value)
                }}
                /> */}
                 <TextField
                
                id="outlined-required"
                label="Enter Mobile No"
                value={phoneNo}
                onChange={(e) => {
                  setphoneNo(e.target.value);
                }}
                style={{
                  // width:"20em",
        width:window.innerWidth <= 768?"15em":"20em"

                            // marginLeft:"37.5%"
                }}
                variant="outlined"
              />
                </GridItem>
                

                <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom:"1%",marginTop:"0.5em",display:"flex",flexDirection:"column",alignItems:"center"}}>

                <Button
       startIcon={ <PictureAsPdfSharpIcon fontSize={"small"} style={{color:"white",
        marginRight: "0.5em", // Add space to the right of the icon
       
       }} />}
          variant="contained"
          color="primary"
          style={{
            justifyContent: "flex-start", // Align content to the start
            textAlign: "left",           // Align text to the left
            width:window.innerWidth <= 768?"17em":"23em",

            height:"4em",
       
          // fontSize:13,
            backgroundColor:"#196c91"
          }}
          onClick={handleButtonClickpdf}
        >
          {/*  */}
        {PDFDetails.name?PDFDetails.name:"Attach Cv as a PDF"}  

        </Button>
        <input
        type="file"
        accept="application/pdf" // Restrict to PDF files
        ref={fileInputRefpdf}
        onChange={handleFileChangepdf}
        style={{ display: 'none' }}
      />
</GridItem>
<GridItem xs={12} sm={12} md={12} lg={12} style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <p style={{color:"red",fontSize:window.innerWidth <= 768?"0.8em":"0.9em",textAlign:"center",fontWeight:"bold",alignSelf:"center"}}> Note: CV should be with a recent photograph & in pdf format</p>

</GridItem>
<GridItem xs={12} sm={12} md={12} lg={12}
 style={{marginBottom:"1%",marginTop:"0.5em",display:"flex",flexDirection:"column",alignItems:"center"}}>

<Button variant="contained"  style={{backgroundColor:"#196c91",color:"white",
//  width: "8em",
width:window.innerWidth <= 768?"17em":"23em",

height:"4em",
        
        }}
         disabled={loading}
onClick={() => {

  console.log(name)
  if(selectJob){
    if(name){
      if(phoneNo){
        if(ScanPDF){
          setLoading(true)
   var obj = {
  // branch_id:parseInt(branchID),
  job_id:selectJob,
 name:name,
 phone:phoneNo,
 email:"",
 pdf_base64:ScanPDF.split('base64,')[1]

}
console.log(obj)
 fetch("https://reports.mpl-labs.pk:8443/4DACTION/JobApplyCVSubmissionWS_api", {
    method: "POST",
    body: JSON.stringify(obj)
}).then((res) => res.json()).then((response) => {
  console.log(response)
  if(response.status=="Successful")
  {
    // alert("Your job application is submitted successfully. Our HR department will be in touch with you in case you are shortlisted for this job position.Thank you ")
    // window.location.reload();
    setsubmissionSuccess(true)
  }
  else{
    alert("Not Submitted Try again !")
    setLoading(false)
  }
})
        }else{
    alert("Please Attached Cv as a PDF !")

        }
      }else{
    alert("Please Enter Mobile number !")

      }
    }else{
    alert("Please Enter Your Name !")

    }
  }else{
    alert("Please Select Job !")
  }
}}       


>
                   SUBMIT
                </Button>
                </GridItem>
                </>:submissionSuccess?<>
                <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom:"1%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}  >

<img src={thanks} style={{
width: '18em',
alignSelf:"center"}} class="center"/>
                <p style={{color:"red",fontSize:window.innerWidth <= 768?"0.8em":"0.9em",textAlign:"center",fontWeight:"bold",alignSelf:"center"}}>Our HR department will be in touch with you in case you are shortlisted for this job position.</p>


</GridItem>
                </>: <>
                <GridItem xs={12} sm={12} md={12} lg={12} style={{marginBottom:"1%",marginTop:"0.5em",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}  >

                 <img src={novacancies} style={{
  width: '20em',
  alignSelf:"center"}} class="center"/>
  </GridItem>
                </>}
    </GridContainer>
  //     <MaterialTable
  // options={{
  //   // selection: true,
  //   // paging:true,
  //   // minBodyHeight:40,
  //   headerStyle: {
  //     backgroundColor: '#196c91',
  //     color: '#FFF'
  //   }
   
  //   // showSelectAllCheckbox:SelectionControl
  // }}
  //     title="Find a Test / Service"
  //     // columns={this.state.columns}
  //     // data={this.state.data}
    
  
  //         />
  )
}